export const APIURLS ={
    questions : "/v1/questions",
    answer: "/answer",
    createSurvey: "/createSurvey",
    languages: '/languages',
    initialQuestion: '/initial_question',
    followUpQuestion: '/followup_question',
    url: '/url',
    statments: '/statments',
    submit: '/submit'
};