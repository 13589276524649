import React, { useState, useEffect, useCallback } from "react";
import QuestionList from "./QuestionsList";
import settingIcon from "../../assets/settings-gear-svgrepo-com (3).svg";
import kyaraImage from "../../assets/cropped-Site-Logo.png";

import wematterlogo from "../../assets/weMatterFullLogo.svg";
import LanguageModal from "./LanguageChangeModal";
import infoIcon from "../../assets/info-circle-svgrepo-com.svg";
import { getLanguages, getLogoUrl } from "../../services/apiServices";
import "normalize.css";
import "./index.css";
import info from "../../assets/info.svg";
import menu from "../../assets/menu.svg";

export default function DesktopView() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfo, setIsInfo] = useState(false);
  const [logo, setLogo] = useState();
  const [showInfo, setShowInfo] = useState(false);

  const openModal = (isInfo) => {
    setIsModalOpen(true);
    setIsInfo(isInfo);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const queryParams = new URLSearchParams(window.location.search);
  const getLogoUrls = useCallback(async () => {
    const surveyId = queryParams.get("surveyId");
    const { logo_url } = await getLogoUrl(surveyId);
    setLogo(logo_url?.logo_url);
  });

  useEffect(() => {
    getLogoUrls();
  }, [getLogoUrls]);

  const surveyId = queryParams.get("surveyId");

  useEffect(() => {
    const getAnswerType = async () => {
      const { languages } = await getLanguages(surveyId);
      setShowInfo(languages?.[0]?.bouncetime);
    };

    getAnswerType();
  }, []);

  return (
    <>
      <div className="main-container flex items-center justify-center content">
        <div className="lg:w-3/4 md:w-3/4 mobile:w-full shadow-lg">
          <div
            className="
          bg-white mobile:h-16 lg:h-20 md:h-20  flex items-center justify-between px-4 py-1.75 shadow-md"
          >
            <div className="flex items-center gap-2">{
              showInfo !== 1 &&
              <img
                className="logo-imgs mobile:h-16 lg:h-20 md:h-20 object-contain"
                src={wematterlogo}
                alt="we-matter-logo"
              />
              }
              {logo && (
                <img
                  className=" logo-imgs mobile:h-16 lg:h-24 md:h-24 object-contain"
                  alt="company logo"
                  src={logo}
                />
              )}
            </div>
            {showInfo !== 1 ? (
              <div className="w-1/5 flex justify-end items-center gap-2.5">
                <div className="h-8 mobile:h-6">
                  <img
                    onClick={() => openModal(true)}
                    src={info}
                    className="cursor-pointer h-8 mobile:h-6"
                    alt="info-icon "
                  />
                </div>
              </div>
            ): <img
            className="logo-imgs mobile:h-16 lg:h-20 md:h-20 object-contain"
            src={wematterlogo}
            alt="we-matter-logo"
          />}
          </div>
          <LanguageModal
            closeModal={closeModal}
            isInfo={isInfo}
            isOpen={isModalOpen}
          />
          <QuestionList />
        </div>
      </div>
    </>
  );
}
